import React from "react";
import { Button, Chip } from "@legion-ui/core";
import { IconX } from "@tabler/icons-react";

function NewRelease({ release, releaseDate, setNewRelease }) {
  const handleClick = () => {
    window.location = "/release-notes/website";
  };

  const handleClose = () => {
    localStorage.setItem("newRelease", release);
    setNewRelease(release.keyNewRelease);
  };
  return (
    <>
      <div className="bx--grid bx--col-lg-12 release-banner">
        <div className="headrelease">
          <div className="wording">
            <Chip
              size="sm"
              variant="solid"
              color="success"
            >
              New
            </Chip>
            <span>
              Latest Version{" "}
              <span style={{ fontWeight: "700" }}>{release} - {releaseDate} - Legion iOS 3.1.0 & Legion Prototype 2.0.1!</span>
            </span>
          </div>

          <div className="action">
            <Button
              onClick={handleClick}
              color="primary"
              variant="transparent"
              size="sm"
            >
              See the Details
            </Button>
            <span
              onClick={handleClose}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconX size={16} color="black" />
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewRelease;
